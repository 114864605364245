



import { Component, Vue, Emit } from 'vue-property-decorator';

@Component
export default class Signup extends Vue {
  @Emit()
  private pageBack(): void {
    // return window.sessionStorage.getItem('fromPath');
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }
}
